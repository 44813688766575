import React, { useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import emailjs from "emailjs-com"; // Import EmailJS SDK
import "./trafficmanagement/traffic.css";

const Form = () => {
  // Function to generate a random CAPTCHA
  const generateCaptcha = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      captcha += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return captcha;
  };

  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [userInput, setUserInput] = useState("");
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    product: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const [captchaError, setCaptchaError] = useState(false);

  // Handle CAPTCHA refresh
  const handleRefreshCaptcha = () => {
    setCaptcha(generateCaptcha());
    setUserInput("");
    setMessage("");
  };

  // Handle user input change
  const handleUserInputChange = (e) => {
    setUserInput(e.target.value);
  };

  // Handle form input change
  const handleFormChange = (e) => {
    const { name, value } = e.target;

    // Handle name input validation
    if (name === "name") {
      if (value.length > 16) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          name: "Name cannot exceed 16 characters.",
        }));
        return; // Prevent further updates
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          name: "", // Clear the error when valid
        }));
      }
    }
    // Allow only numeric input for phone number and limit to 12 digits
    if (name === "phone") {
      // Remove any non-numeric characters and limit the length to 12
      const numericValue = value.replace(/\D/g, "");
      if (numericValue.length <= 12) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: numericValue, // Update only if within the length limit
        }));
      }
      return; // Prevent further updates for this field
    }

    // Update form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Validate form fields
  const validateForm = () => {
    let isValid = true;
    const newErrors = { name: "", email: "", phone: "" };

    // Name validation
    if (!formData.name) {
      newErrors.name = "Name is required.";
      isValid = false;
    } else if (!/^[A-Za-z\s]+$/.test(formData.name)) {
      newErrors.name = "Name can only contain letters and spaces.";
      isValid = false;
    } else if (formData.name.length > 16) {
      newErrors.name = "Name cannot exceed 16 characters.";
      isValid = false;
    }

    // Email validation
    if (!formData.email) {
      newErrors.email = "Email is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
      isValid = false;
    }

    // Phone validation
    if (!formData.phone) {
      newErrors.phone = "Phone number is required.";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "Please enter a valid 10-digit phone number.";
      isValid = false;
    }

    // Product validation
    if (!formData.product) {
      newErrors.product = "Please select a product.";
      isValid = false;
    }
    setErrors(newErrors);
    return isValid;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate before submitting
    if (!validateForm()) {
      return; // Don't proceed if validation fails
    }

    if (userInput !== captcha) {
      setMessage("CAPTCHA does not match. Please try again.");
      setCaptchaError(true); // Set captchaError to true when CAPTCHA does not match
      return;
    }

    // Reset the captchaError state when CAPTCHA matches
    setCaptchaError(false);

    // Proceed with sending the email using EmailJS
    const { name, product, email, phone, message } = formData;

    const emailParams = {
      name,
      product,
      email,
      phone,
      message,
    };

    emailjs
      .send(
        "service_5x1c50m",
        "template_c9fgmo3",
        emailParams,
        "xgZ90_Sd9FOaZDo-S" // Use the user ID stored in the .env file
      )
      .then(
        (response) => {
          setMessage("Thank you! We will get back to you soon.");
          setCaptcha(generateCaptcha());
          setUserInput("");
          setFormData({
            name: "",
            product: "",
            email: "",
            phone: "",
            message: "",
          });
        },
        (error) => {
          console.error("EmailJS Error:", error); // Log the error for debugging
          setMessage("Error submitting the form. Please try again.");
        }
      );
  };

  return (
    <div className="bg-[#05182A] ">
      <div className="bg-network bg-no-repeat bg-cover flex justify-center items-center">
        <div className="md:p-0 p-5 md:h-[570px] w-full mx-auto max-w-7xl">
          <h1 className="text-white font-[400] text-[20px] md:text-[30px] md:leading-[40px] md:pl-8 md:pt-10 ">
            Tell us about your requirements, we'll be happy to connect with you
          </h1>

          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-8 md:p-8 bg-transparent mt-10 ">
            <div className="relative">
              <input
                type="text"
                placeholder="Your name"
                name="name"
                value={formData.name}
                onChange={handleFormChange}
                className="bg-[rgba(86,181,245,0.3)] text-white p-3 rounded-2xl border border-gray-400 w-full"
              />
              {errors.name && (
                <p className="text-red-500 text-xs absolute mt-1">
                  {errors.name}
                </p>
              )}
            </div>

            {/* Dropdown for Product selection */}
            <div className="relative">
  <select
    name="product"
    value={formData.product}
    onChange={handleFormChange}
    className="bg-[rgba(86,181,245,0.3)] text-white p-3 rounded-2xl border border-gray-400 w-full appearance-none pr-10"
    required
  >
    <option value="" className="bg-blue-300 text-gray-700">
      Select Product
    </option>
    <option value="Product 1" className="bg-blue-100 text-gray-700">
      Traffic Surveillance
    </option>
    <option value="Product 2" className="bg-blue-100 text-gray-700">
      Crowd Detection
    </option>
    <option value="Product 3" className="bg-blue-100 text-gray-700">
      Gun Detection
    </option>
    <option value="Product 4" className="bg-blue-100 text-gray-700">
      Face Recognition
    </option>
    <option value="Product 5" className="bg-blue-100 text-gray-700">
      Dwell Time Analysis
    </option>
    <option value="Product 6" className="bg-blue-100 text-gray-700">
      Smoke and Fire Detection
    </option>
  </select>
  <div className="absolute inset-y-0 right-3 flex items-center pointer-events-none">
    <svg
      className="w-4 h-4 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 9l-7 7-7-7"
      />
    </svg>
  </div>
  {errors.product && (
    <p className="text-red-500 text-xs absolute mt-1">
      {errors.product}
    </p>
  )}
</div>


            <div className="relative">
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleFormChange}
                className="bg-[rgba(86,181,245,0.3)] text-white p-3 rounded-2xl border border-gray-400 w-full"
              />
              {errors.email && (
                <p className="text-red-500 text-xs absolute mt-1">
                  {errors.email}
                </p>
              )}
            </div>

            <div className="relative">
              <input
                type="text"
                placeholder="Phone number"
                name="phone"
                value={formData.phone}
                onChange={handleFormChange}
                className="bg-[rgba(86,181,245,0.3)] text-white p-3 w-full rounded-2xl border border-gray-400"
              />
              {errors.phone && (
                <p className="text-red-500 text-xs absolute mt-1">
                  {errors.phone}
                </p>
              )}
            </div>

            <div className="col-span-1 md:col-span-2">
              <textarea
                className="w-full p-3 bg-[rgba(86,181,245,0.3)] text-gray-100 rounded-md border-[#AB98BA] border bg-opacity-50 shadow-md"
                rows="4"
                name="message"
                value={formData.message}
                onChange={handleFormChange}
                placeholder="LEAVE A MESSAGE FOR US"
              ></textarea>
            </div>

            <div className="flex flex-col md:flex-row space-x-0 md:space-x-4 items-center col-span-1 md:col-span-2">
              <div className="flex space-x-2 items-center w-full md:mt-0  bg-[rgba(86,181,245,0.3)] p-3 rounded-lg text-gray-300 border-[#AB98BA] border bg-opacity-50 justify-between">
                <span className="select-none ">{captcha}</span>{" "}
                {/* Making CAPTCHA text unselectable */}
                <button onClick={handleRefreshCaptcha}>
                  <RefreshIcon className="h-6 w-6 text-gray-300 hover:text-gray-100" />
                </button>
              </div>
              <input
                type="text"
                value={userInput}
                onChange={handleUserInputChange}
                required
                className={`w-full p-3 mt-4 md:mt-0 bg-[rgba(86,181,245,0.3)] text-gray-100 rounded-md border ${
                  captchaError ? "border-red-500" : "border-gray-400"
                }`}
                placeholder="Enter CAPTCHA"
              />

              <div className="flex justify-end w-full md:w-[50%] mt-4 md:mt-0 hover:transition-all duration-300 ease-in-out transform hover:scale-105">
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="bg-white w-full py-3 p-4 rounded-xl hover:text-white hover:bg-[#92181D]"
                >
                  Submit
                </button>
              </div>

              {message && (
                <p
                  className={`text-center w-full mt-4 text-sm ${
                    captchaError ? "text-red-500" : "text-gray-200"
                  }`}
                >
                  {message}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
