import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const unattended2 = () => {
  return (
    <div
      className=" "
      style={{
        background: "linear-gradient(to left, #310303 0%,  #001F40 71%)",
      }}
    >
      <div className="bg-network w-full lg:h-[550px] flex justify-center items-center">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mx-auto max-w-7xl mt-10 p-6">
        <h1 className="text-white font-[400] text-[25px] md:text-[30px] leading-[37px] md:hidden block ">
              What is AI Unattended Object Detection
            </h1>
          <LazyLoadImage
            src="https://squarebric-media-files.squarebric.com/sivaldevops/assets/dwelltime2.webp"
            alt="traffic-management"
            className="w-full md:h-[350px] h-[300px] rounded-2xl object-cover"
          />
          <div>
            <h1 className="text-white font-[400] text-[25px] md:text-[30px] leading-[37px]  md:block hidden">
              What is AI Unattended Object Detection
            </h1>
            <p className="leading-6 sm:leading-7 md:leading-8 text-justify text-[16px] sm:text-[18px] md:text-[20px] font-[300] text-white mt-4">
            Object detection is an AI-driven computer vision technology that identifies and pinpoints objects such as bags, suitcases, backpacks, and boxes in images or video streams. It plays a crucial role in security by monitoring unattended items and distinguishing between stationary and moving objects, reducing false alarms and improving accuracy.
            </p>
            <p className="leading-6 sm:leading-7 md:leading-8 text-justify text-[16px] sm:text-[18px] md:text-[20px] font-[300] text-white mt-4">
            Sival Devops offers state-of-the-art object detection solutions, ensuring enhanced security and efficient monitoring for industries like transportation, public safety, and critical infrastructure.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default unattended2;
