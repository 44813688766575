import React, { useState, useEffect, useRef } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Import icons from react-icons
import { useNavigate } from 'react-router-dom';
const SolutionsSection = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to track dropdown visibility
  const dropdownRef = useRef(null); // Ref to track the dropdown element
  const navigate = useNavigate();
  const solutions = [
    { title: "Traffic Management", link: "/trafficmanagemnt" },
    { title: "Face Recognition", link: "/facerecognition" },
    { title: "Smoke and Fire Detection", link: "/smokefire" },
    { title: "Gun Detection", link: "/gundetection" },
    { title: "Crowd Monitoring", link: "/crowd" },
    { title: "Dwell Time Analysis", link: "/dwellanalysis" },
    { title: "Intrusion Detection", link: "/intrusion" },
    { title: "Unattended Detection", link: "/unattended" },
  ];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown visibility
  };

  // Close dropdown if clicked outside of the components
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Close dropdown
      }
    };

    document.addEventListener("mousedown", handleClickOutside); // Listen for outside clicks

    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup event listener
    };
  }, []);
  const handleSolutionClick = (solution) => {
    console.log("Navigating to:", solution.link);
    navigate(solution.link); // Navigate to the link
  };

  return (
    <div className="h-auto w-[230px] fixed right-1 z-10 top-[17%]" ref={dropdownRef}>
    <div>
      <h1
        className="sm:text-[18px]  md:text-[24px] font-[600] relative bg-[#1F1F1F] px-5 py-1 cursor-pointer flex items-center justify-between w-full text-[#F39C12] rounded-t-[15px] shadow-lg transition-all hover:bg-[#333] hover:shadow-2xl"
        onClick={toggleDropdown} // Toggle dropdown on click
      >
        <span>Our Solutions</span>
        {/* Dropdown Icon */}
        {isDropdownOpen ? <FaChevronUp className="text-orange-400 transition-transform duration-300" /> : <FaChevronDown className="text-orange-400 transition-transform duration-300" />}
      </h1>

      {/* Dropdown Menu */}
      {isDropdownOpen && (
        <div className="space-y-0 bg-[#1F1F1F] rounded-b-[15px] shadow-2xl">
          {solutions.map((solution, index) => (
            <div
              key={index}
              className="text-blue-300 sm:text-[16px] md:text-[18px] font-medium tracking-wide hover:bg-[#333] transition-colors duration-300 cursor-pointer rounded-lg p-3"
              onClick={() => handleSolutionClick(solution)} // Trigger navigation
            >
              <div className="text-white font-[300] hover:text-blue-400">
                {solution.title}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
  );
};

export default SolutionsSection;
