import React from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const untend3 = () => {
  return (
    <div
      className="w-full "
      style={{
        background: "linear-gradient(to right, #310303 0%,  #001F40 75%)",
      }}
    >
      <div className=" w-full  flex justify-center items-center pb-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mx-auto max-w-7xl mt-10 p-6">
          <div>
            <h1 className="text-white font-[400] text-[25px] md:text-[30px] leading-[37px] ">
              Experience Sival Devops AI-Unattended Object Detection
            </h1>
            <p className="leading-6 sm:leading-7 md:leading-8 text-justify text-[16px] sm:text-[18px] md:text-[20px] font-[300] text-white mt-4">
              Experience the advanced capabilities of Sival Devops AI-Unattended
              Object Detection, a state-of-the-art solution designed to enhance
              security and situational awareness.
            </p>
            <p className="leading-6 sm:leading-7 md:leading-8 text-justify text-[16px] sm:text-[18px] md:text-[20px] font-[300] text-white mt-4">
              Our AI technology detects and monitors unattended items such as
              bags, suitcases, backpacks, and boxes in real-time. Using advanced
              algorithms, it accurately identifies stationary objects,
              distinguishes them from moving items, and reduces false alarms.
            </p>
            <p className="leading-6 sm:leading-7 md:leading-8 text-justify text-[16px] sm:text-[18px] md:text-[20px] font-[300] text-white mt-4">
              Whether in airports, public transport hubs, malls, or critical
              infrastructure, Sival Devops provides a reliable and efficient way
              to monitor for potential security threats, ensuring faster
              response times and greater safety across various industries.
            </p>
          </div>

          <LazyLoadImage
            src="https://squarebric-media-files.squarebric.com/sivaldevops/assets/dwelltime3.webp"
            alt="traffic-management"
            className="w-full md:h-[400px] h-[300px] rounded-2xl object-cover"
          />

          <div className="text-start  md:col-span-2 ">
            <ul className="list-disc pl-5 space-y-4 font-[300] md:text-[20px] leading-[25px] text-justify  mt-4">
              <li className="text-white">
                <strong>Real-Time Detection:</strong> Instantly identifies
                unattended objects in video streams, ensuring timely alerts and
                faster responses.
              </li>
              <li className="text-white">
                <strong> Object Classification:</strong> Recognizes various
                object types, such as bags, suitcases, and boxes, prioritizing
                potentially hazardous items for quick action.
              </li>
              <li className="text-white">
                <strong> AI-Powered Accuracy:</strong> Uses advanced AI
                algorithms to accurately differentiate between stationary
                objects and moving items, minimizing false alarms.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default untend3;
