import React from "react";
import Runway from "../../../assets/Runway.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
const Crowd5 = () => {
  return (
    <div className="bg-network">
      <div className=" max-w-7xl mx-auto px-6 pt-10">
        <h1 className="text-[#FFFFFF]  text-[30px] pt-3 font-[600] leading-[47px]">
          Where We can use Unattended object detection
        </h1>
        <div className="relative mt-10 pb-10">
          <div className="h-auto p-5">
            <LazyLoadImage 
              src="https://squarebric-media-files.squarebric.com/sivaldevops/assets/zigzag.png"
              alt="zigzag"
              className="h-[600px]"
            />
            <LazyLoadImage 
              src="https://squarebric-media-files.squarebric.com/sivaldevops/assets/zigzag2.png"
              alt="zigzag2"
              className="h-[200px]"
            />
          </div>

          <div className="absolute top-0 left-12 flex items-center space-x-4">
            <LazyLoadImage  src={Runway} alt="public1" className="w-24 h-24" />
            <div>
              <h1 className="text-white text-[25px] font-[500]  leading-[50px]">
                Airports
              </h1>
              <p className="leading-6 sm:leading-7 md:leading-8 text-justify text-[16px] sm:text-[18px] md:text-[20px] font-[300] text-white pl-4">
                Unattended object detection helps identify suspicious bags,
                luggage, or items left behind in terminals, baggage claim areas,
                and security checkpoints, preventing potential threats.
              </p>
            </div>
          </div>

          <div className="absolute top-[200px] left-0 flex items-center space-x-4">
            <LazyLoadImage 
              src="https://squarebric-media-files.squarebric.com/sivaldevops/assets/public3.png"
              alt="retail"
              className="w-24 h-24"
            />
            <div>
              <h1 className="text-white text-[25px] font-[500]  leading-[50px]">
                Public Transportation
              </h1>
              <p className="leading-6 sm:leading-7 md:leading-8 text-justify text-[16px] sm:text-[18px] md:text-[20px] font-[300] text-white">
                In train stations, bus stations, and metro stations, unattended
                items like bags, boxes, or suitcases can be quickly detected to
                ensure the safety of passengers and prevent security breaches.
              </p>
            </div>
          </div>

          <div className="absolute top-[360px] left-28 flex items-center space-x-4">
            <LazyLoadImage 
              src="https://squarebric-media-files.squarebric.com/sivaldevops/assets/shopping.png"
              alt="smartcity"
              className="w-24 h-24"
            />
            <div>
              <h1 className="text-white text-[25px] font-[500]  leading-[50px]">
                Shopping Malls and Retail Stores
              </h1>
              <p className="leading-6 sm:leading-7 md:leading-8 text-justify text-[16px] sm:text-[18px] md:text-[20px] font-[300] text-white">
                Unattended object detection systems can monitor stores and mall
                entrances to identify bags or merchandise left behind, reducing
                theft and improving safety.
              </p>
            </div>
          </div>

          <div className="absolute top-[540px] left-2 flex items-center space-x-4">
            <LazyLoadImage 
              src="https://squarebric-media-files.squarebric.com/sivaldevops/assets/smartcity.png"
              alt="healthcare"
              className="w-24 h-24"
            />
            <div>
              <h1 className="text-white text-[25px] font-[500]  leading-[50px]">
                Smart Cities and Public Spaces
              </h1>
              <p className="leading-6 sm:leading-7 md:leading-8 text-justify text-[16px] sm:text-[18px] md:text-[20px] font-[300] text-white">
                In urban areas with large gatherings, unattended object
                detection can be used in parks, streets, and squares to enhance
                public safety and prevent potential threats.
              </p>
            </div>
          </div>

          <div className="absolute top-[730px] left-28 flex items-center space-x-4">
            <LazyLoadImage 
              src="https://squarebric-media-files.squarebric.com/sivaldevops/assets/healthcare.png"
              alt="transportation"
              className="w-24 h-24"
            />
            <div>
              <h1 className="text-white text-[25px] font-[500]  leading-[50px]">
                Hospitals and Healthcare Facilities:
              </h1>
              <p className="leading-6 sm:leading-7 md:leading-8 text-justify text-[16px] sm:text-[18px] md:text-[20px] font-[300] text-white">
                Unattended objects in waiting areas, patient rooms, or public
                spaces can be detected to ensure that items are not left in a
                way that could block pathways or pose a safety risk.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Crowd5;
