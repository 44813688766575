import React from "react";

function Untend4() {
  return (
    <div
      className=""
      style={{
        background: "linear-gradient(to left, #310303 0%,  #001F40 71%)",
      }}
    >
      <div className=" text-white flex items-center justify-center bg-network md:h-[550px]">
        <div className="max-w-7xl p-6">
        <h1 className="text-white font-[400] text-[25px] md:text-[30px] leading-[30px]  ">
            Why We Need In Unattended Object Detection?
          </h1>
          <p className="leading-6 sm:leading-7 md:leading-8 text-justify text-[16px] sm:text-[18px] md:text-[20px] font-[300] text-white mt-4">
            Unattended object detection is essential in modern security systems
            to address growing safety concerns in public and private spaces.
            Here's why it is crucial:
          </p>
          <ul className="space-y-4 list-decimal pl-5 leading-6 sm:leading-7 md:leading-8 text-justify text-[16px] sm:text-[18px] md:text-[20px] font-[300] text-white mt-4">
            <li>
              <span className="font-bold text-white">
                Detecting Potential Threats:
              </span>{" "}
              Unattended objects, especially in crowded areas, could pose
              security threats, such as explosives or contraband. Detection
              systems help identify these items before they become a serious
              danger.
            </li>
            <li>
              <span className="font-bold text-white">
                Preventing Terrorism and Crime:
              </span>{" "}
              In high-risk areas like airports, train stations, and government
              buildings, unattended objects can be used for illegal activities.
              Early detection helps in preventing criminal acts and terrorist
              threats.
            </li>
            <li>
              <span className="font-bold text-white">
                Ensuring Public Safety:
              </span>{" "}
              Public spaces like shopping malls, schools, and stadiums need
              constant monitoring. Left-behind items could be dangerous or
              obstruct evacuation routes in case of an emergency. Real-time
              object detection keeps the area safer for everyone.
            </li>
            <li>
              <span className="font-bold text-white">
                Faster Response Time:
              </span>{" "}
              The ability to detect unattended objects immediately allows
              security teams to respond quickly, either by inspecting the object
              or evacuating the area, preventing potential incidents before they
              escalate.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Untend4;
