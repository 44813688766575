
import React from "react"
import Untend5 from "./Untend5"
import Untend6 from "./Untend6"


function Untend7() {
  return (
    <div className=''>
    <div className='p-0'>
    {/* Show Section6 only on large devices and up */}
    <div className="hidden md:block"
    style={{
      background: "linear-gradient(to left, #310303 0%,  #001F40 71%)",
    }}>

    <Untend5/>
    </div>
  
    {/* Show Section5 only on mobile devices */}
    <div className="block md:hidden"
    style={{
      background: "linear-gradient(to left, #310303 0%,  #001F40 71%)",
    }}>

    <Untend6/>
    </div>
  </div>
  </div>
  )
}

export default Untend7