// JobPosting.jsx

import { useParams, useNavigate } from "react-router-dom";
import React from "react";
import { jobData } from "./jobdata";
import jobweb from "../../../assets/jobdeveloper.svg";
import loc from "../.../../../../assets/loc.png";
import Exp from "../.../../../../assets/Exp.png";
import hour from "../.../../../../assets/hour.png";
import jobtype from "../.../../../../assets/jobtype.png";
import Vacancy from "../.../../../../assets/vacancy.png";
import working from "../.../../../../assets/workingdays.png";
import {
  FaFacebookF,
  FaLinkedin,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// Icon mapping object
const iconMap = {
  loc,
  jobtype,
  Exp,
  Vacancy,
  hour,
  working,
};

// Requirements list component
const RequirementsList = ({ items }) => (
  <ul className="space-y-4">
    {items.map((item, index) => (
      <li key={index} className="flex items-start space-x-3">
        <div className="flex-shrink-0 w-1.5 h-1.5 mt-2 rounded-full bg-red-500"></div>
        <span className="text-gray-300">{item}</span>
      </li>
    ))}
  </ul>
);

const JobPosting = () => {
  const { jobType } = useParams();
  const navigate = useNavigate();
  const job = jobData[jobType];

  if (!job) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
        <div className="text-center">
          <h1 className="text-3xl font-bold mb-4">Job Not Found</h1>
          <button
            onClick={() => navigate("/careers")}
            className="bg-red-600 px-4 py-2 rounded-lg hover:bg-red-700 transition"
          >
            Back to Careers
          </button>
        </div>
      </div>
    );
  }

  return (
    <div
      className="min-h-screen"
      style={{
        background: "linear-gradient(to left, #310303 0%, #001F40 100%)",
      }}
    >
      <div className=" p-8 text-white">
        <div className="max-w-7xl mx-auto">
          {/* Title Section */}
          <h1 className="text-3xl font-bold mb-6 mt-10">{job.title}</h1>

          {/* Purpose and Image Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-10">
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4 border-b border-red-500 pb-2 inline-block">
                Job Purpose
              </h2>
              <p className="text-gray-300 text-[18px] text-justify">
                {job.jobPurpose}
              </p>
            </section>

            <section className="flex justify-center items-center ">
              <LazyLoadImage
                src={jobweb}
                alt="Job illustration"
                effect="blur"
                className="w-full h-auto max-w-md object-cover rounded-lg shadow-lg md:pt-4 mb-4"
              />
            </section>

            {/* <section className=" mx-auto p-4 relative">
      <div 
        className="absolute inset-0 top-2 rounded-tl-3xl right-0 rounded-br-3xl left-16 bottom-14  bg-white bg-opacity-10"
        // style={{ 
        //   background: 'linear-gradient(117.95deg, rgba(49, 3, 3, 0.4) 7.36%, rgba(202, 100, 100, 0.4) 104.76%)',
        //   zIndex: -1
        // }}
      />
      <div className="pl-0 pt-8">
        <img
          src={jobweb}
          alt="Workspace illustration"
          className="w-full h-auto max-w-md rounded-lg shadow-lg "
          loading="lazy"
        />
      </div>
    </section> */}
          </div>

          {/* Responsibilities and Summary Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 ">
            <div className="sm:mt-[0px] md:mt-[-110px]">
              <h2 className="text-2xl font-semibold mb-4 border-b border-red-500 pb-2 inline-block">
                Key Responsibilities
              </h2>
              <ul className="space-y-3">
                {job.responsibilities.map((responsibility, index) => (
                  <li
                    key={index}
                    className="text-gray-300 flex items-start text-justify"
                  >
                    <span className="mr-2">•</span>
                    {responsibility}
                  </li>
                ))}
              </ul>
            </div>

            <div className="bg-cover bg-center bg-[#90272B]/30 rounded-xl p-6 backdrop-blur-3xl mt-10 md:max-w-4xl mx-auto">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-semibold">Job Summary</h3>
                <button
                  onClick={() => {
                    /* Add your apply logic here */
                  }}
                  className="bg-[#90272B] text-white px-4 py-2 rounded-3xl hover:bg-red-700 transition"
                >
                  Apply Now
                </button>
              </div>

              <div className="grid grid-cols-2 gap-4">
                {job.jobDetails.map((detail, index) => (
                  <div key={index} className="flex items-center space-x-3">
                    <LazyLoadImage
                      src={iconMap[detail.icon]}
                      alt={detail.label}
                      effect="blur"
                      className="w-6 h-6"
                    />
                    <div>
                      <p className="text-gray-400 text-sm">{detail.label}</p>
                      <p className="text-white">{detail.value}</p>
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-6 pt-6 border-t border-gray-400 flex justify-between items-center">
                <p className="text-white font-roboto">Share with Others</p>
                <div className="flex space-x-4">
                  {[
                    { Icon: FaFacebookF, link: "#" },
                    { Icon: FaTwitter, link: "#" },
                    { Icon: FaLinkedin, link: "#" },
                    { Icon: FaInstagram, link: "#" },
                  ].map(({ Icon, link }, index) => (
                    <a
                      key={index}
                      href={link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:opacity-80 transition"
                    >
                      <Icon
                        style={{ color: "#90272B" }}
                        className="bg-white p-1.5 rounded-full h-[30px] w-[30px]"
                      />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Requirements Section */}
          <div className="mt-10">
            <div className="grid md:grid-cols-2 gap-10">
              <div className="space-y-6">
                <h2 className="text-2xl font-bold text-white border-b border-red-500 pb-2 mb-4">
                  Qualifications
                </h2>
                <RequirementsList items={job.qualifications} />
              </div>

              <div className="space-y-6 xl:pl-20">
                <h2 className="text-2xl font-bold text-white border-b border-red-500 pb-2 mb-4">
                  Behavioral Competencies
                </h2>
                <RequirementsList items={job.competencies} />
              </div>
            </div>
          </div>

          {/* Back to Careers Button */}
          <div className="mt-10 flex justify-center">
            <button
              onClick={() => navigate("/careers")}
              className="bg-[#90272B] text-white px-6 py-2 rounded-3xl hover:bg-red-700 transition"
            >
              Back to Careers
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPosting;
