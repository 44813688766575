import React from 'react'
import jobs from "../../../assets/jobs1.png"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
function Section1() {
  return (
    <div className="relative w-full h-64 sm:h-80 md:h-96 lg:h-[500px]">
  {/* Background Image */}
  <LazyLoadImage 
    src={jobs} // Replace with your actual image path
    alt="Jobs Banner"
    className="w-full h-full object-cover"
  />
</div>

  )
}

export default Section1